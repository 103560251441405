import React, { Fragment } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  makeStyles,
} from "@material-ui/core";
import "typeface-roboto";

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: "rgba(82,222,151,0.7)",
  },
}));

function removeA(arr) {
  var what,
    a = arguments,
    L = a.length,
    ax;
  while (L > 1 && arr.length) {
    what = a[--L];
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
}
const ListDevices = (props) => {
  const clasess = useStyles();
  //const head = props.data.head !== (null || undefined) ? props.data.head : [];
  const body = props.data !== (null || undefined) ? props.data : [];
  const headItems = [
    "Last Update",
    "Meter Id",
    "Billing Type",
    "Status Valve",
    "Battry Level",
    "Interval",
    "Description",
  ];

  // if(body.length !== 0){
  //     Object.keys(body[0]).forEach(function(key) {
  //         headItems.push(key);
  //     });

  //     // console.log('headItems',headItems)
  //     // removeA(headItems, 'id');
  //     // removeA(headItems, 'typeId');
  //     // removeA(headItems, 'tenantId');
  //     // removeA(headItems, 'areaId');
  //     // removeA(headItems, 'internalId');
  //     // console.log('headItems',headItems)
  // }
  return (
    // <div>TEST {props.data}</div>
    <TableContainer>
      <Table>
        <TableHead className={clasess.head}>
          <TableRow>
            {headItems.map((val, index) => {
              return (
                <TableCell align="center" key={index}>
                  {val}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {body.map((val1, index1) => {
            return (
              <TableRow
                onClick={() => props.click(val1.id)}
                key={index1}
                style={{ cursor: "pointer" }}
              >
                {headItems.map((val2, index2) => {
                  return (
                    <TableCell key={index2} align="center">
                      {val1[val2]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListDevices;
